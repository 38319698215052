import after from './after.png';
import bg from './bg.png';
import object from './object.gif';
// import loadingObject from './loading_object.png';
import loading from './loading.gif';
import obj1 from './1.gif';
import obj2 from './2.gif';
import obj3 from './3.gif';
import obj4 from './4.gif';
import obj5 from './5.gif';
import obj6 from './6.gif';
import obj7 from './7.gif';
import obj8 from './8.gif';
import obj9 from './9.gif';
import obj10 from './10.gif';

const objects = [
  obj1,
  obj2,
  obj3,
  obj4,
  obj5,
  obj6,
  obj7,
  obj8,
  obj9,
  obj10,
]

export { after, bg, object, loading, objects };