import {
  compose,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';

import { reducer as localeReducer } from './features/locale';
import { reducer as authReducer } from './features/Auth';
import { reducer as notifyReducer } from './features/Notify';
import { reducer as gameReducer } from './features/Game';
import { reducer as historyReducer } from './features/History';
import { reducer as chatReducer } from './features/Chat';
import { reducer as settingsReducer, initial as initialSettings } from './features/Settings';
import { reducer as jackpotReducer } from './features/Jackpot';
import { reducer as megaWinsReducer } from './features/MegaWins';
import { reducer as promoReducer } from './features/PromoList';
import { reducer as socketErrorReducer } from './features/SocketErrorBoundary';
import { reducer as newYearReducer, persistentReducer as newYearSettingsReducer } from './features/NewYear';

function configureStore(extra) {
  const middlewares = [
    thunk.withExtraArgument({ ...extra, socket: extra.socket, chatSocket: extra.chatSocket }),
  ];

  const reducer = createReducer();

  const store = createStore(
    reducer,
    {
      settings: initialSettings,
    },
    compose(
      applyMiddleware(...middlewares),
      persistState(['settings', 'newYearSettings']),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) : (arg => arg),
    ),
  );

  extra.socket.addSocketHandlers(store.dispatch)
  extra.chatSocket.addSocketHandlers(store.dispatch)

  return store;
}

function createReducer() {
  return combineReducers({
    locale: localeReducer,
    auth: authReducer,
    notify: notifyReducer,
    game: gameReducer,
    history: historyReducer,
    chat: chatReducer,
    settings: settingsReducer,
    jackpot: jackpotReducer,
    megaWins: megaWinsReducer,
    promo: promoReducer, 
    socketError: socketErrorReducer,
    newYear: newYearReducer,
    newYearSettings: newYearSettingsReducer,
  });
}


export { createReducer };
export default configureStore;
