import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SHOW_MEGA_WINS:
            return {
                ...state,
                wins: [...state.wins, action.payload],
            };
        case actionTypes.CLOSE_MEGA_WINS:
            return {
                ...state,
                wins: state.wins.filter(elem => elem.winId !== action.payload),
            };
        default:
            return state;
        
    };

};