import BaseApi from '../BaseApi';
class PromoApi extends BaseApi {
  getPromoList = (token) => this.sendQuery(
    this.queryTypes.GET,
    `/api/common/get_promo?token=${token}`,
  )
};

export default PromoApi;

