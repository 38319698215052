import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from './';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import frame from './img/back.svg';
import mobileFrame from './img/mobile.svg';
import close from './img/close2.svg';
import NewYearMusic from './NewYearMusic';

import './NewYear.scss';
import { useCallback } from 'react';

const b = block('new-year');

const NewYear = () => {
    const dispatch = useDispatch();
    const locale = useSelector(state => state.locale.locale);
    const nick = useSelector(state => state.auth.nick);
    const { isOpen } = useSelector(state => state.newYear);
    const { statistic } = useSelector(state => state.newYear);
    const { platform, currency } = useSelector(state => state.auth);

    const { bets_count, max_win_amount, win_amount_sum } = statistic ?? {};


    const closeNewYear = useCallback(() => {
        dispatch(actions.closeNewYear())
    }, [dispatch])

    return (
        <>
            <div className={b()} style={isOpen ? null : { visibility: 'hidden', zIndex: 0 }}>
                <div className={b('main-container')}>
                    <div className={b('img-container')}>
                        <SVGInline className={b('frame-img')} alt='frame' svg={platform === 'desktop' ? frame : mobileFrame} />
                        <div className={b('close-button')} onClick={closeNewYear}>
                            <SVGInline className={b('close-icon')} alt='close' svg={close} />
                        </div>
                        <div className={b('content')}>
                            <div className={b('title')}>
                                <span className={b('title-text')}>{locale.newYearTitle}</span>
                            </div>
                            <div className={b('text-container')}>
                                <span className={b('text')}>{`${locale.text1} ${nick ? nick : locale.player}!`}</span>
                                <span className={b('text')}>{locale.text2}</span>
                                <span className={b('text')}>{locale.text3}</span>
                                <span className={b('text')}>{locale.text4}</span>
                            </div>
                            <div className={b('statistics')}>
                                <span className={b('text')}>{locale.statisticTitle}</span>
                                <span className={b('text')}>{`${locale.statistic1} ${win_amount_sum} ${currency}!`}</span>
                                <span className={b('text')}>{`${locale.statistic2} ${max_win_amount} ${currency}!`}</span>
                                <span className={b('text')}>{`${locale.statistic3} ${bets_count} ${locale.statistic4}`}</span>
                            </div>
                            <div className={b('text-container')}>
                                <span className={b('text')}>{locale.text5}</span>
                                <span className={b('text')}>{locale.text6}</span>
                                <span className={b('text')}>{locale.text7}</span>
                                <span className={b('text')}>{locale.text8}</span>
                            </div>
                            <div className={b('footer')}>
                                <span className={b('text-footer')}>{locale.fiableGames}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <NewYearMusic />
        </>
    )
}

export default NewYear;