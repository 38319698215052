import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { actions } from '../../';

import Input from 'components/Input';
import { icons } from 'components/Header/img/icons';
import closeSvg from '../img/close.svg';
import saveSvg from '../img/save.svg';

import './EditUser.scss';

const b = block('edit-user');

const EditUser = ({ switchIsOpenEdit }) => {
  const dispatch = useDispatch();

  const [newNick, setNick] = useState('');
  const [newIconId, setIconId] = useState(0);

  const { locale } = useSelector(state => state.locale);
  const { icon_id, nick } = useSelector(state => state.auth);

  useEffect(() => {
    setNick(nick);
    setIconId(icon_id);
  }, [nick, icon_id])

  const iconsList = useMemo(() => Object.entries(icons).map((t, index) => <SVGInline
    className={b('icon', { active: newIconId.toString() === t[0]}).toString()}
    svg={t[1]}
    key={index}
    onClick={() => setIconId(t[0])}
  />), [newIconId])

  const updateUser = useCallback(() => {
    dispatch(actions.userUpdate({ nick: newNick && newNick.length > 0 ? newNick : undefined, icon_id: +newIconId }))
  }, [dispatch, newNick, newIconId])

  return <article className={b()}>
  <div className={b('top-block')}>
    <Input value={newNick} callBack={setNick} placeholder={locale.nick} />
    <div className={b('item')}>
      <SVGInline className={b('save').toString()} svg={saveSvg} onClick={updateUser} />
    </div>
    <div className={b('item')}>
      <SVGInline className={b('close').toString()} svg={closeSvg} onClick={switchIsOpenEdit} />
    </div>
  </div>
  <div className={b('icons-block')}>
    {iconsList}
  </div>
</article>;
}

export default EditUser;