import success from '../img/success.svg';
import error from '../img/error.svg';
import win from '../img/win.svg';

export const notificationImg = {
  success: {
    img: success,
    type: 'success',
  },
  error: {
    img: error,
    type: 'error',
  },
  win: {
    img: win,
    type: 'win',
  },
};