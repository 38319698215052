import * as actions from './actions';
export const gameError = {
    session_expired: actions.showGameSessionError,
    incorrect_session: actions.showGameTokenError,
};

export const chatError = {
    session_expired: actions.showChatSessionError,
    incorrect_session: actions.showChatTokenError,
};
