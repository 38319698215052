import { addNotify } from 'features/Notify/redux/actions';

const actionTypes = {
  SELECT_ACTIVE_PROMO_BY_ID: 'promo/SELECT_ACTIVE_PROMO_BY_ID',
  CLOSE_PROMO: 'promo/CLOSE_PROMO',
  SET_IS_PROCESS: 'promo/SET_IS_PROCESS',
  GET_PROMO_LIST: 'promo/GET_PROMO_LIST',
};

const getPromoList = () => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true })
  const { api } = extra;
  const { locale } = getState();
  const { token } = getState().auth;
  const { lang } = getState().locale;
  const response = await api.promo.getPromoList(token);
  if (response.success) {
    dispatch({ type: actionTypes.GET_PROMO_LIST, payload: {promo: response.data.promo, lang} });
  } else {
    dispatch(addNotify(locale.error, 'error'));
  }
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const selectActivePromoById = id => async (dispatch) => {
  dispatch({ type: actionTypes.SELECT_ACTIVE_PROMO_BY_ID, payload: id });
}

const closePromo = () => async (dispatch) => {
  dispatch({ type: actionTypes.CLOSE_PROMO });
}

export {
  actionTypes,
  selectActivePromoById,
  closePromo,
  getPromoList,
};