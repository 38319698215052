import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import arrowSvg from './img/arrow.svg';

import './Statistic.scss';

const colors = [
  { min: 0, max : 2, color: 'var(--statistic-color1)' },
  { min: 2, max : 3, color: 'var(--statistic-color2)' },
  { min: 3, max : 4, color: 'var(--accent-color)' },
  { min: 4, max : 10, color: 'var(--statistic-color3)' },
  { min: 10, max : 10000, color: 'var(--statistic-color4)' },
]

const Statistic = () => {
  const b = block('statistic');
  const coefsHistory = useSelector(state => state.game.coefsHistory);
  const [isOpen, setOpen] = useState(false);

  const coefs = useMemo(() => coefsHistory.map(t => <div
    className={b('coef')}
    key={t.game_id}
    style={{ background: colors.find(tempColor => t.crash_point >= tempColor.min && t.crash_point < tempColor.max)?.color || 'var(--statistic-color4)' }}>
      {t.crash_point}
  </div>), [b, coefsHistory])

  return <section className={b({ isOpen })}>
    <div className={b('coefs-container', { isOpen })}>
      {coefs}
    </div>
    <SVGInline svg={arrowSvg} className={b('arrow', { isOpen }).toString()} onClick={() => setOpen(!isOpen)} />
  </section>
}

export default Statistic;