import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
    const { payload } = action;
    switch (action.type) {
        case actionTypes.SELECT_ACTIVE_PROMO_BY_ID:
            return {
                ...state,
                openPromoId: action.payload,
            };
        case actionTypes.CLOSE_PROMO:
            return {
                ...state,
                openPromoId: null,
            };
        case actionTypes.SET_IS_PROCESS:
            return {
                ...state,
                isProcess: payload,
            };
        case actionTypes.GET_PROMO_LIST:
            const getLocalizedValue = (data) => data[payload.lang] || data['en'] || Object.values(data)[0] || "";

            return {
                ...state,
                promo: payload.promo.map(({ images, texts, ...promoItem }) => ({
                    ...promoItem,
                    image: getLocalizedValue(images) && `https://fiable-admin.com/promo/${getLocalizedValue(images)}`,
                    text: getLocalizedValue(texts),
                })),
            }
        default:
            return state;

    };

};