import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { categories } from './data';

import Button from 'components/Button';
import MyBets from './MyBets';
import Bets from './Bets';
import TopWins from './TopWins';
import { PromoList } from 'features/PromoList';

import './History.scss';

const History = ({ isClosingHistory }) => {
  const b = block('history');
  const [category, changeCategory] = useState(categories.results || '')
  const locale = useSelector(state => state.locale.locale);
  const { promo } = useSelector(state => state.promo);

  const buttons = Object.entries(categories).map(t => (
    <div className={b('button-container')} key={t[0]}>
      {t[1].id === 6 ? <div className={b('promo-button', { isActive: category.id === 6 })}>
        <Button
          text={locale[t[1].textIdent]}
          size='container'
          isUppercase
          fontSize="small"
          color={category.id === t[1]?.id ? 'orange-promo' : 'dark-empty-promo'}
          callBack={() => changeCategory(t[1])}
        />
        {promo?.length ? <div className={b('promo-count', { isSmallFont: promo.length > 9 })}>{promo.length}</div> : null}
      </div> :
        <Button
          text={locale[t[1].textIdent]}
          size='container'
          color={category.id === t[1]?.id ? 'orange' : 'dark'}
          fontSize='small'
          isUppercase
          callBack={() => changeCategory(t[1])} />
      }
    </div>
  ))

  const neededComponent = useMemo(() => {
    switch (category.id) {
      case 0:
        return <Bets />;
      case 1:
        return <MyBets />;
      case 2:
        return <TopWins />;
      case 6:
        return <PromoList />
      default:
        return null;
    }
  }, [category.id])

  return <article className={b({ isClosingHistory })}>
    <div className={b('buttons-container')}>
      {buttons}
    </div>
    <div className={b('content-container')}>
      {neededComponent}
    </div>
  </article>
}

export default History;