import React, { useState, useCallback, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions as promoActions } from 'features/PromoList';

import BasketModel from 'components/Basket';
import Header from 'components/Header';
import Subheader from 'components/Subheader';
import Statistic from 'components/Statistic';
import { History } from 'features/History';
import { EditUser } from 'features/Auth';
import { Chat } from 'features/Chat';
import { Jackpot } from 'features/Jackpot';
import GameWindow from './GameWindow';
import { MegaWins } from 'features/MegaWins';
// import { NewYear } from 'features/NewYear';
import { Promo } from 'features/PromoList';


import './Game.scss';

const b = block('game');

const Game = () => {
  const dispatch = useDispatch();
  const platform = useSelector(state => state.auth.platform);
  const jackpots = useSelector(state => state.jackpot.jackpots);

  const scrollRef = useRef(null);
  // History animation
  const [isOpenHistory, changeIsOpenHistory] = useState(false);
  const [isClosingHistory, changeIsClosingHistory] = useState(false);
  const switchIsOpenHistory = useCallback(() => {
    if (isOpenHistory) {
      changeIsClosingHistory(true)
      setTimeout(() => changeIsOpenHistory(!isOpenHistory), 300)
    } else {
      changeIsClosingHistory(false)
      changeIsOpenHistory(!isOpenHistory)
    }
  }, [isOpenHistory])
  // User edit animation
  const [isOpenEdit, changeIsOpenEdit] = useState(false);
  const switchIsOpenEdit = useCallback(() => {
    changeIsOpenEdit(!isOpenEdit)
  }, [isOpenEdit])

  useEffect(() => {
    dispatch(promoActions.getPromoList());
  }, [dispatch]);

  return <article className={b({ platform })}>
    {platform === 'mobile' ? <React.Fragment>
      <div className={b('header-layout', { isOpen: isOpenHistory || isOpenEdit })}>
        <Header switchIsOpenHistory={switchIsOpenHistory} switchIsOpenEdit={switchIsOpenEdit} isOpenHistory={isOpenHistory} />
      </div>
      {isOpenHistory && <History isClosingHistory={isClosingHistory} />}
      {isOpenEdit && <EditUser switchIsOpenEdit={switchIsOpenEdit} />}
      <MegaWins />
      {/* <NewYear /> */}
      <Promo />
      <div className={b('jackpot-layout')}>
        <Jackpot />
      </div>
      <div className={b('center-block')}>
        <div className={b('statistic-layout')}>
          <Statistic />
        </div>
        <GameWindow />
      </div>
      <div className={b('card-history-layout')}>
      </div>
      <div className={b('basket-layout')}>
        <BasketModel />
      </div>
      <div className={b('chat-layout')} ref={scrollRef}>
        <Chat scrollRef={scrollRef} />
      </div>
    </React.Fragment> : <React.Fragment>
      <div className={b('left-header-layout')}>
        <Subheader />
      </div>
      <div className={b('statistic-layout')}>
        <Statistic />
      </div>
      <div className={b('right-header-layout')}>
        <Header switchIsOpenEdit={switchIsOpenEdit} />
        {isOpenEdit && <EditUser switchIsOpenEdit={switchIsOpenEdit} />}
      </div>
      <div className={b('history-layout')}>
        <History />
      </div>
      <div className={b('game-layout')}>
        <GameWindow />
      </div>
      <div className={b('basket-layout')}>
        <BasketModel />
      </div>
      <div className={b('chat-layout', { isOpen: isOpenEdit })}>
        <Jackpot />
        <div className={b('chat-container')} style={{ height: `calc(100% - ${3.2 * jackpots.length}rem)`}}>
          <Chat />
        </div>
      </div>
      <MegaWins />
      {/* <NewYear /> */}
      <Promo />
    </React.Fragment>}
  </article>
}

export default Game;