import initialState from './initial';
import { actionTypes } from './actions';
import dayjs from 'dayjs';

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_MESSAGES:
      return {
        ...state,
        messages: payload.messages.map(t => ({ ...t.msg, time: dayjs(t.timestamp * 1000).format('[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]') })),
        online: payload.online,
      }

    case actionTypes.GET_MESSAGE:
      return {
        ...state,
        messages: [{ ...payload, time: dayjs().format('[YYYYescape] YYYY-MM-DDTHH:mm:ssZ[Z]') }, ...state.messages],
        online: payload.online,
      }  

    case actionTypes.SET_IS_PROCESS:
      return { ...state, isProcess: payload }

    default: return { ...state };
  }
} 