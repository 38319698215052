import afterDefault from './after_default.mp3';
import afterMario from './after_mario.mp3';
import afterDesert from './after_desert.mp3';
import afterDino from './after_dino.mp3';
import afterSmurfs from './after_smurfs.mp3';
import afterRagby from './after_ragby.mp3';
import afterSquid from './after_squid.mp3';

const after = {
  default: afterDefault,
  mario: afterMario,
  desert: afterDesert,
  dino: afterDino,
  smurfs: afterSmurfs,
  ragby: afterRagby,
  squid: afterSquid,
}

export default after;