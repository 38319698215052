import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import rule1Png from './img/rule1.png';
import rule2Png from './img/rule2.png';
import rule3Png from './img/rule3.png';
import infoSvg from './img/info.svg';
import Button from 'components/Button';

import './RulesButton.scss';

const b = block('rules-button');

const RulesButton = () => {
  const [isOpen, setOpen] = useState(false);
  const locale = useSelector(state => state.locale.locale);

  return <article className={b({ isOpen })}>
    <SVGInline svg={infoSvg} className={b('icon', { isOpen }).toString()} onClick={() => setOpen(!isOpen)} />
    <div className={b('row')}>
      <div className={b('header')}>{locale.howToPlay}</div>
    </div>
    <div className={b('row')}>
      <div className={b('number')}>1</div>
      <div className={b('vertical-container')}>
        <img src={rule1Png} className={b('image')} alt="" />
        <span className={b('text')}>{locale.rule1}</span>
      </div>
    </div>
    <div className={b('row')}>
      <div className={b('number')}>2</div>
      <div className={b('vertical-container')}>
        <img src={rule2Png} className={b('image')} alt="" />
        <span className={b('text')}>{locale.rule2}</span>
      </div>
    </div>
    <div className={b('row')}>
      <div className={b('number')}>3</div>
      <div className={b('vertical-container')}>
        <img src={rule3Png} className={b('image')} alt="" />
        <span className={b('text')}>{locale.rule3}</span>
      </div>
    </div>
    <div className={b('row')}>
      <Button link="/rules" text={locale.detailedRules} color="orange" />
    </div>
  </article>
}

export default RulesButton;