import bgDefault from './bg_default.mp3';
import bgMario from './bg_mario.mp3';
import bgDesert from './bg_desert.mp3';
import bgDino from './bg_dino.mp3';
import bgSmurfs from './bg_smurfs.mp3';
import bgRagby from './bg_ragby.mp3';
import bgSquid from './bg_squid.mp3';
import bgSquidGame from './bg_squid_game.mp3';

const bg = {
  default: bgDefault,
  mario: bgMario,
  desert: bgDesert,
  dino: bgDino,
  smurfs: bgSmurfs,
  ragby: bgRagby,
  squid: bgSquid,
  squidGame: bgSquidGame,
}

export default bg;