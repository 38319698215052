import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import Modal from 'components/Modal';
import JackpotItem from './JackpotItem';
import JackpotWindow from './JackpotWindow';

import { actions } from '../';

import './Jackpot.scss';

const b = block('jackpot');

const Jackpot = () => {
  const dispatch = useDispatch();
  const { jackpots } = useSelector(state => state.jackpot);
  const winner = useSelector(state => state.jackpot.winners[0])

  const closeWindow = useCallback(() => {
    dispatch(actions.removeWinner())
  }, [dispatch])
  
  const items = useMemo(() => jackpots.map(t => <div key={t.id} className={b('item')}>
      <JackpotItem jackpot={t} />
    </div>), [jackpots])

  return <article className={b()}>
    {items}
    {winner ? <Modal close={closeWindow} isCross key={winner?.jackpot_id}>
      <JackpotWindow jackpot={winner} />
    </Modal> : null}
  </article>
}

export default Jackpot;