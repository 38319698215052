import React, { useState, useEffect, useCallback } from 'react';
import block from 'bem-cn';

import './Advice.scss';

const b = block('advice');

const Advice = ({ text, coordinateXL, coordinateXR, coordinateYT, coordinateYB, corner }) => {
  const [isVisible, setVisible] = useState(false);
  useEffect(() => {
    setTimeout(visibleFunction, 15 * 1000)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const visibleFunction = useCallback(() => {
    setVisible(true);
    setTimeout(() => setVisible(false), 5 * 1000)
  }, []);


  return <div className={b({ corner , isVisible})} 
    style={{ right: coordinateXR || 'initial',
    left: coordinateXL || 'initial',
    top: coordinateYT || 'initial',
    bottom: coordinateYB || 'initial',
    [`border${corner}Radius`]: 0 }}>
    {text}
  </div>
}

export default Advice;