import initialState from './initial';
import { actionTypes } from './actions';
import styles from '../data'

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_INFO:
      return {
        ...state,
        coef: payload.current_point,
        periodType: payload.game_status === 'playing' ? 1 :
        (state.periodType === 2 && payload.game_status !== 'playing' && (state.roundID !== payload.game_id) && payload.current_point === 1) ? 1 : 2,
        roundID: payload.game_id,
        betAmountInRound: state.periodType === 1 && payload.game_status === 'ended' ? 0 : state.betAmountInRound,
        cashedBets: state.periodType === 2 && (payload.game_status === 'playing' || (
         payload.game_status !== 'playing' && (state.roundID !== payload.game_id) && payload.current_point === 1
        )) ?
        [] : state.cashedBets,
        coefsHistory: state.periodType === 1 && payload.game_status !== 'playing' ?
          [{ crash_point: payload.current_point, game_id: payload.game_id}, ...state.coefsHistory] : state.coefsHistory, 
      }

    case actionTypes.SET_BET_AMOUNT_IN_ROUND:
      return {
        ...state,
        betAmountInRound: payload,
      }  

    case actionTypes.GET_SETTINGS_SUCCESS:
      let styleCode = payload.properties?.style_code || 'default';
      if (styles[styleCode] === undefined) {
        styleCode = 'default';
      } else if (styles[styleCode].title) {
        document.title = styles[styleCode].title;
      }
      return { ...state,
        limits: { min: payload.min_bet, max: payload.max_bet, win: payload.max_win },
        pouseDuration: payload.pause_duration,
        restartDuration: payload.restart_time_ms,
        gameName: payload.properties?.name,
        styleCode, 
       }

    case actionTypes.GET_ROUND_ID:
      return { ...state, roundID: payload.game_id }

    case actionTypes.GET_TOP_WINS:
      return { ...state, topWins: payload.top_wins.sort((a, b) => b.win_amount - a.win_amount)}

    case actionTypes.SET_ROUND_INFO:
      return {
        ...state,
      }

    case actionTypes.GET_COEFS_HISTORY:
      return { ...state,
        coefsHistory: payload,
      }

    case actionTypes.SET_IS_PROCESS:
      return { ...state, isProcess: payload }

    case actionTypes.GET_CASHED_OUT:
      return { ...state, cashedBets: [payload, ...state.cashedBets]}  

    default: return state;
  }
}