import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Odometer from 'react-odometerjs';
import block from 'bem-cn';

import './JackpotWindow.scss';
import './Odometr.scss';

const b = block('jackpot-window');

const JackpotWindow = ({ jackpot }) => {
  const [amount, setAmount] = useState(0);
  const currency = useSelector(state => state.auth.currency);
  const locale = useSelector(state => state.locale.locale);

  useEffect(() => {
    setTimeout(() => setAmount(jackpot?.winner_amount?.[currency]), 500)
  }, [currency, jackpot, jackpot.winner_amount])
  
  return <section className={b()}>
    <div className={b('title')}>
      {locale.congratulations}
    </div>
    <div className={b('text-block')}>
      <div className={b('text')}>{locale.youWonJackpot}</div>
      <div className={b('text')}>{jackpot.jackpot_name?.replace(',', ' ')}!</div>
    </div>
    <div className={b('amount')}>
      <Odometer value={amount} format="(ddd).dd" theme="default" />
      <div className={b('currency')}>
        {currency}
      </div>
    </div>
  </section>
}

export default JackpotWindow;