import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';

import Button from 'components/Button';

import './Rules.scss';

const Rules = () => {
  const b = block('rules');
  const { locale } = useSelector(state => state.locale);
  const { currency } = useSelector(state => state.auth);
  const { win } = useSelector(state => state.game.limits);

  return <article className={b()}>
    <div className={b('header')}>{locale.rules}</div>
    <div className={b('button-container')}>
      <Button link="/game" text={locale.playNow} color='orange' />
    </div>
    <section className={b('text')} dangerouslySetInnerHTML={{__html: locale.rulesHtml(win, currency)}} />
  </article>
}

export default Rules;