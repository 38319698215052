import React from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector } from 'react-redux';

import { Settings } from 'features/Settings';
import { RulesButton } from 'components/Rules';
// import NewYearButton from 'features/NewYear/NewYearButton';

import homeSvg from './img/home.svg';
import guardSvg from './img/guard.svg';

import './Subheader.scss';

const Subheader = ({ switchIsOpenHistory, switchIsOpenEdit }) => {
  const b = block('subheader');
  const homeUrl = useSelector(state => state.auth.homeUrl);
  const isFrame = useSelector(state => state.auth.isFrame);

  return <header className={b()}>
    <div className={b('guard')}>
      <SVGInline svg={guardSvg} className={b('guard-icon').toString()} />
    </div>
    {/* <div className={b('item', {isNewYear: true })}>
      <NewYearButton/>
    </div> */}
    <div className={b('item')}>
      <Settings />
    </div>
    {(homeUrl && homeUrl?.length && !isFrame) && <a className={b('item')} href={homeUrl} >
      <SVGInline svg={homeSvg} className={b('icon').toString()} />
    </a>}
    <div className={b('item')}>
      <RulesButton />
    </div>
  </header>
}

export default Subheader;