const actionTypes = {
  SET_SOUND: 'SET_SOUND',
  SET_MUSIC: 'SET_MUSIC',
  SET_ANIMATIONS: 'SET_ANIMATIONS',
}

const setSound = value => async dispatch => dispatch({ type: actionTypes.SET_SOUND, payload: value });

const setMusic = value => async dispatch => dispatch({ type: actionTypes.SET_MUSIC, payload: value });

const setAnimations = value => async dispatch => dispatch({ type: actionTypes.SET_ANIMATIONS, payload: value });

export {
  actionTypes,
  setSound,
  setMusic,
  setAnimations,
}