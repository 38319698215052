import { actionTypes } from './actions';
import initialState from './initial';

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_JACKPOTS: {
      return {
        ...state,
        jackpots: payload.sort((a, b) => b.type - a.type),
      }
    }

    case actionTypes.ADD_WINNER: {
      return {
        ...state,
        winners: [...state.winners, payload],
      }
    }

    case actionTypes.REMOVE_WINNER: {
      return {
        ...state,
        winners: state.winners.slice(1),
      }
    }

    default: return state;
  }
}

export default reducer;