import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import styles from '../../../data';

import wickPng from '../img/wick.png';
import sparksGif from '../img/sparks.gif';

import './Pause.scss';

const b = block('pause');

const Pause = () => {
  const { restartDuration } = useSelector(state => state.game)
  const { locale } = useSelector(state => state.locale);
  const styleCode = useSelector(state => state.game.styleCode);

  return <article className={b()}>
    {locale.nextRound}
    <div className={b('wick')} style={{
      animationDuration: `${(restartDuration - 2000) / 1000}s`,
      background: `url(${styles[styleCode]?.loading || wickPng}) no-repeat`,
      backgroundSize: styles[styleCode]?.pauseBackgroundSize || 'cover',
      backgroundPosition: 'right',
      height: styles[styleCode]?.loadingHeight || '0.5rem',
      }}>
      {!styles[styleCode]?.notNeedSparks && <img
        src={styles[styleCode]?.loadingObject || sparksGif}
        className={b('sparks')} alt=""
        style={{ height: styles[styleCode].loadingObjectHeight || '5rem', left: styles[styleCode].loadingObjectLeft || '-2.5rem' }}
        />}
    </div>
  </article>
}

export default Pause;