import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { roundingFunc } from 'services/helpers/roundingFunc';

import './Bets.scss';
import { useMemo } from 'react';

const Bets = () => {
  const b = block('bets');
  const locale = useSelector(state => state.locale.locale);
  const userID = useSelector(state => state.auth.userID);
  const bets = useSelector(state => state.history.bets) || [];

  const resultList = useMemo(() => (
    bets.map((t, i) => (
      <div key={`${t.user_id}${i}`} className={b('item', { isWin: t.isWin, isMine: userID === t.user_id })}>
        <div className={b('column')}>
          {t.nick || t.user_id}
        </div>
        <div className={b('column')}>
          {`${roundingFunc(t.bet_amount)} ${t.currency}`}
        </div>
        <div className={b('column')}>
          {t.coefficient || '-'}
        </div>
        <div className={b('column')}>
          {t.win_amount ? `${roundingFunc(t.win_amount)} ${t.currency}` : '-'}
        </div>
      </div>
    ))
  ), [b, bets, userID])

  return <section className={b()}>
    <div className={b('header')}>
      <div className={b('column')}>
        {locale.player}
      </div>
      <div className={b('column')}>
        {locale.amount}
      </div>
      <div className={b('column')}>
        {locale.odd}
      </div>
      <div className={b('column')}>
        {locale.win}
      </div>
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default Bets;
