import React, { useCallback, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { actions } from '../';

import PromoListItem from '../PromoListItem/PromoListItem';

import './PromoList.scss';

const b = block('promo-list');

const PromoList = () => {
    const dispatch = useDispatch();
    const { promo } = useSelector(state => state.promo);
    const locale = useSelector(state => state.locale.locale);

    const onPromoClick = useCallback((id) => {
        dispatch(actions.selectActivePromoById(id))
    }, [dispatch]);

    return (
        <div className={b()}>
            { promo?.length ? promo.map(item => <PromoListItem key={item.id} imgSrc={item?.image} titleText={item?.name || ''}  description={item?.text} onClick={onPromoClick} id={item.id} />) : <div className={b('no-promo')}>{locale.noPromo}</div> }
        </div>
    );
};

export default PromoList;
