const actionTypes = {
  GET_MESSAGES: 'chat/GET_MESSAGES',
  GET_MESSAGE: 'chat/GET_MESSAGE',
  SET_IS_PROCESS: 'chat/SET_IS_PROCESS',
}

const getMessages = data => dispatch => dispatch({ type: actionTypes.GET_MESSAGES, payload: data});

const getMessage = data => dispatch => dispatch({ type: actionTypes.GET_MESSAGE, payload: data});

const sendMessage = (message, callback) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true })
  const { chatSocket } = extra;
  const { icon_id, nick, userID } = getState().auth;
  await chatSocket.getSocket().send(JSON.stringify({ type: 'message', data: { icon_id, nick, userID, message }}));
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
  callback();
}

export {
  actionTypes,
  getMessages,
  sendMessage,
  getMessage,
}