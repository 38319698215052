import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { actions } from './';

import closeImg from '../Auth/view/img/close.svg';
import megaWinImg from './img/megaWin.svg';
import MegaWinMusic from './MegaWinMusic';

import './MegaWins.scss';

const b = block('mega_wins');

const MegaWins = () => {
    const dispatch = useDispatch();
    const currency = useSelector(state => state.auth.currency);
    const locale = useSelector(state => state.locale.locale);
    const win = useSelector(state => state.megaWins.wins?.[0]);

    const [isOpen, setIsOpen] = useState(Boolean(win));

    useEffect(() => {
        setIsOpen(Boolean(win));
    }, [win]);

    const closeMegaWinButton = useCallback(() => {
        setIsOpen(false);

        setTimeout(() => {
            dispatch(actions.closeMegaWins(win?.winId));
        }, 250);
    }, [dispatch, win]);
    const clickOnSVG = useCallback((evt) => evt.stopPropagation(), []);

    const filledSVG = useMemo(() => 
        megaWinImg
            .replace(/{amount}/g, win?.winAmount ?? '0' )
            .replace(/{currency}/g, currency ?? 'TND')
            .replace(/{MEGA}/g,  (locale.megaWinMega || 'MEGA').toUpperCase())
            .replace(/{WIN}/g, (locale.megaWinWin || 'WIN').toUpperCase()),
        [win, currency, locale.megaWinMega, locale.megaWinWin]
    );

    return !win ? null: (
        <div key={win.winId} className={b({ isClosed: !isOpen })} onClick={closeMegaWinButton}>
            <SVGInline svg={filledSVG} className={b('image').toString()} onClick={clickOnSVG}/>
            <div onClick={closeMegaWinButton} className={b('close-button')}>
                <SVGInline svg={closeImg} className={b('item').toString()} />
            </div>
            <MegaWinMusic/>
        </div>
    );
};

export default MegaWins;