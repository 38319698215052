import BaseApi from '../BaseApi';

class HistoryApi extends BaseApi {
  constructor(baseUrl) {
    super();
    this.baseUrl = baseUrl;
  }

  getTopWins = () => this.sendQuery(
    this.queryTypes.GET,
    `${this.baseUrl}/top_wins`,
  )

  getMyBets = (count = 10, token) => this.sendQuery(
    this.queryTypes.GET,
    `${this.baseUrl}/bet_history?bet_quantity=${count}&token=${token}`,
  )

}

export default HistoryApi;