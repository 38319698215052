import GameApi from './GameApi';
import UserApi from './UserApi';
import HistoryApi from './HistoryApi';
import PromoApi from './PromoApi/PromoApi';
import NewYearApi from './NewYearApi';

class Api {
  constructor(baseUrl = '') {
    this.game = new GameApi(baseUrl);
    this.user = new UserApi(baseUrl);
    this.history = new HistoryApi(baseUrl);
    this.promo = new PromoApi();
    this.newYear = new NewYearApi(baseUrl);
  }
}

export default Api;
