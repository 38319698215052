import { addNotify } from 'features/Notify/redux/actions';
import { actions as authActions } from 'features/Auth';

const actionTypes = {
  GET_JACKPOTS: 'GET_JACKPOTS',
  ADD_WINNER: 'ADD_WINNER',
  REMOVE_WINNER: 'REMOVE_WINNER',
}

const getJackpots = data => dispatch => dispatch({ type: actionTypes.GET_JACKPOTS, payload: data })

const addWinner = data => (dispatch, getState) => {
  const { userID, currency, balance } = getState().auth;
  if (data.winner_id === userID) {
    dispatch({ type: actionTypes.ADD_WINNER, payload: data })
    dispatch(authActions.getBalance(balance + data?.winner_amount?.[currency]));
  } else {
    const { locale } = getState().locale;
    dispatch(addNotify(locale.anotherWinnerNotify(data.winner_name, data.jackpot_name.replace(',', ' '), data.winner_amount[currency], currency), 'default', 4000))
  }
}

const removeWinner = () => dispatch => dispatch({ type: actionTypes.REMOVE_WINNER })

export {
  actionTypes,
  getJackpots,
  addWinner,
  removeWinner,
}