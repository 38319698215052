import { initialState, initialPersistantState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.USER_NEW_YEAR:
            return {
                ...state,
                statistic: action.payload,
            }
        case actionTypes.SHOW_NEW_YEAR:
            return {
                ...state,
                isOpen: true,
            }
        case actionTypes.CLOSE_NEW_YEAR:
            return {
                ...state,
                isOpen: false,
            }

        default:
            return state;

    };

};

export const persistentReducer = (state = initialPersistantState, action) => {
    switch (action.type) {
        case actionTypes.SHOW_NEW_YEAR:
            return {
                ...state,
                wasShown: true,
            }
        default:
            return state;

    };

};