import React, { useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { GridLoader } from 'react-spinners';

import { getIconById } from 'components/Header/img/icons';
import { actions } from '../';
import { roundingFunc } from 'services/helpers/roundingFunc';

import './TopWins.scss';

const LOADER_STYLE = { position: 'fixed', top: '50%', transform: 'translateY(-50%)', zIndex: 100 };

const TopWins = () => {
  const dispatch = useDispatch();
  const b = block('top-wins');
  const locale = useSelector(state => state.locale.locale);
  const { topWins, isProcess } = useSelector(state => state.history);

  useEffect(() => {
    dispatch(actions.getTopWins());
  }, [dispatch])

  const topWinsList = useMemo(() => (
    topWins.map((t, i) => (
      <div key={i} className={b('item')}>
        <div className={b('column')}>
          <SVGInline svg={getIconById(t.icon_id)} className={b('icon').toString()} />
          {t.nick || t.user_id}
        </div>
        <div className={b('column', { type: 'names' })}>
          <div className={b('row')}>{locale.bet}</div>
          <div className={b('row')}>{locale.odd}</div>
          <div className={b('row')}>{locale.win}</div>
        </div>
        <div className={b('column', { type: 'values' })}>
          <div className={b('row')}>{`${roundingFunc(t.bet_amount)} ${t.currency}`}</div>
          <div className={b('row')}>{t.coefficient || '0'}</div>
          <div className={b('row')}>{`${roundingFunc(+t.win_amount)} ${t.currency}`}</div>
        </div>
      </div>
    ))
  ), [b, locale, topWins])

  return <section className={b()}>
    <GridLoader loading={isProcess} color="var(--accent-color)" size={50} css={LOADER_STYLE}/>
    <div className={b('items')}>
      {topWinsList}
    </div>
  </section>
}

export default TopWins;