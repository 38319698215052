import { actions as gameActions } from 'features/Game';
import { addNotify } from 'features/Notify/redux/actions';
import { getJackpots } from 'features/Jackpot/logic/actions';

const actionTypes = {
  OPEN_FRAME_SUCCESS: 'auth/OPEN_FRAME_SUCCESS',
  GET_SETTINGS_SUCCESS: 'auth/GET_SETTINGS_SUCCESS',
  GET_BALANCE: 'auth/GET_BALANCE',
  GET_RESULT: 'auth/GET_RESULT',
  LOG_OUT: 'auth/LOG_OUT',
  SET_PLATFORM: 'auth/SET_PLATFORM',
  SET_HOME_URL: 'auth/SET_HOME_URL',
  USER_UPDATE_SUCCESS: 'auth/USER_UPDATE_SUCCESS',
  SET_IS_PROCESS: 'auth/SET_IS_PROCESS',
}

const intervalId = null;

const openFrame = (data, token) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.OPEN_FRAME_SUCCESS, payload: { ...data, token: token } });
  dispatch(gameActions.getRoundId(data));
  dispatch(gameActions.setRoundInfo(data));
  if (data.crash_points) {
    dispatch(gameActions.getCoefsHistory(data.crash_points));
  }
  if (data.jackpot) {
    dispatch(getJackpots(data.jackpot))
  }
  clearInterval(intervalId)
  setInterval(intervalId => dispatch(setPlatform()), 5000)
}

const getBalance = balance => dispatch => {
  dispatch({ type: actionTypes.GET_BALANCE, payload: balance });
};

const setPlatform = () => dispatch => dispatch({ type: actionTypes.SET_PLATFORM });

const logOut = () => async (dispatch, getState, extra) => dispatch({ type: actionTypes.LOG_OUT });

const setHomeUrl = url => dispatch => dispatch({ type: actionTypes.SET_HOME_URL, payload: url })

const userUpdate = info => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { token, nick, icon_id } = getState().auth;
  const { locale } = getState().locale;
  const response = await api.user.userUpdate({ ...{ nick, icon_id }, ...info }, token);
  if (response.success) {
    dispatch(addNotify(locale.userUpdateSuccess, 'success'));
    dispatch({ type: actionTypes.USER_UPDATE_SUCCESS, payload: { ...{ nick, icon_id }, ...info }})
  } else {
    dispatch(addNotify(locale.userUpdateFail, 'success'));
  }
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

export {
  actionTypes,
  getBalance,
  openFrame,
  logOut,
  setPlatform,
  setHomeUrl,
  userUpdate,
}
