import BaseApi from '../BaseApi';

class NewYearApi extends BaseApi {
    constructor() {
      super(`/api/common`);
    }

    getUserNewYearStatistic = (userId) => this.sendQuery(
        this.queryTypes.POST,
        `/get_new_year_statistic`,
        { user_id: userId }
    )
  }
  
  export default NewYearApi;