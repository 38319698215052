import React from 'react';
import { useSelector } from 'react-redux';
import Odometer from 'react-odometerjs';
import block from 'bem-cn';

import './JackpotItem.scss';
import './Odometr.scss';

const b = block('jackpot-item');

const JackpotItem = ({ jackpot }) => {
  const splitedName = jackpot.name?.split(',') || [];
  const currency = useSelector(state => state.auth.currency);
  const locale = useSelector(state => state.locale.locale);
  
  return <section className={b()}>
    <div className={b('name')}>
      {splitedName[0]}
    </div>
    <div className={b('amount')}>
      <Odometer value={jackpot.amount?.[currency]} format="(ddd).dd" theme="default" />
      <div className={b('currency')}>
        {currency}
      </div>
    </div>
    {jackpot.last_winner_name ? <div className={b('winner')}>
      {`${jackpot.last_winner_name?.split('').map((t, i) => i > 0 && i < 3 ? '*' : t).join('')} ${locale.won} ${jackpot.last_winner_amount?.[currency]?.toFixed(2)} ${currency}`}
    </div> : null}
    <div className={b('name')}>
      {splitedName[1]}
    </div>
  </section>
}

export default JackpotItem;