import { addNotify } from 'features/Notify/redux/actions';

const actionTypes = {
    SHOW_NEW_YEAR: 'newYear/SHOW_NEW_YEAR',
    CLOSE_NEW_YEAR: 'newYear/CLOSE_NEW_YEAR',
    USER_NEW_YEAR: 'newYear/USER_NEW_YEAR',
};

const getUserNewYearStatistic = () => async (dispatch, getState, extra) => {
    const { api } = extra;
    const { userID } = getState().auth;
    const { locale } = getState().locale;
    const response = await api.newYear.getUserNewYearStatistic(userID);
    if (response.success) {
        dispatch({ type: actionTypes.USER_NEW_YEAR, payload: response.data.statistic });
    } else {
        // dispatch(addNotify(locale.errorMessages.defaultMessage, 'error'));
    }
}

const openNewYear = () => (dispatch) => {
    dispatch({ type: actionTypes.SHOW_NEW_YEAR });
}

const closeNewYear = () => (dispatch) => {
    dispatch({ type: actionTypes.CLOSE_NEW_YEAR });
}

export {
    actionTypes,
    getUserNewYearStatistic,
    openNewYear,
    closeNewYear,
};