import BaseApi from '../BaseApi';

class UserApi extends BaseApi {
  constructor(baseUrl) {
    super();
    this.baseUrl = `${baseUrl}/user`;
  }

  userUpdate = (info, token) => this.sendQuery(
    this.queryTypes.POST,
    `${this.baseUrl}/update?token=${token}`,
    info,
  )

}

export default UserApi;