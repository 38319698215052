import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.LOG_OUT:
      return { ...initialState }
      
    case actionTypes.OPEN_FRAME_SUCCESS:
      return {
        ...state,
        isAuth: true,
        userID: payload.user_id,
        currency: payload.currency,
        token: payload.token,
        icon_id: +payload.icon_id,
        nick: payload.nick,
        balance: +(Math.trunc(payload.balance * 100) * 0.01).toFixed(2)
      }

    case actionTypes.USER_UPDATE_SUCCESS:
      return {
        ...state,
        ...payload,
      }  

    case actionTypes.GET_BALANCE: 
      return { ...state, balance: +(Math.trunc(payload * 100) * 0.01).toFixed(2) }

    case actionTypes.GET_RESULT: {
      return { ...state, balance: payload.new_balance ? +(Math.trunc(payload.new_balance * 100) * 0.01).toFixed(2) : state.balance }
    }

    case actionTypes.SET_HOME_URL:
      return { ...state, homeUrl: payload || state.homeUrl }

    case actionTypes.SET_PLATFORM:
      return { ...state, platform: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop' }

    case actionTypes.SET_IS_PROCESS:
      return { ...state, isProcess: payload }

    default: return { ...state };
  }
}