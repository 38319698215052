import React, { useState, useEffect, useMemo } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector } from 'react-redux';

import { Settings } from 'features/Settings';
import { RulesButton } from 'components/Rules';
import Advice from 'components/Advice';
// import NewYearButton from 'features/NewYear/NewYearButton';

import burgerSvg from './img/burger.svg';
import homeSvg from './img/home.svg';
import pencil from './img/pencil.svg';
import closeSvg from './img/close.svg';

import { getIconById } from './img/icons';

import './Header.scss';

const Header = ({ switchIsOpenHistory, switchIsOpenEdit, isOpenHistory }) => {
  const b = block('header');
  const userID = useSelector(state => state.auth.userID);
  const currency = useSelector(state => state.auth.currency);
  const balance = useSelector(state => state.auth.balance);
  const nick = useSelector(state => state.auth.nick);
  const icon_id = useSelector(state => state.auth.icon_id);
  const homeUrl = useSelector(state => state.auth.homeUrl);
  const { promo } = useSelector(state => state.promo);
  const { locale } = useSelector(state => state.locale);
  const [tempBalance, setTempBalance] = useState(balance);
  const [differentBalance, setDifferentBalance] = useState(0);

  useEffect(() => {
    let newDifferentBalance = +(balance - tempBalance).toFixed(2);
    if (newDifferentBalance > 0) {
      newDifferentBalance = `+${newDifferentBalance}`;
    }
    setDifferentBalance(newDifferentBalance);
    setTempBalance(+balance);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance])

  const differentBlock = useMemo(() => (
    <span key={balance} className={b('different-balance', { isReduce: differentBalance < 0 }, { isIncreace: differentBalance.toString().indexOf('+') !== -1 })}>
      {`  ${differentBalance !== 0 ? differentBalance : ''}`}
    </span>
  // eslint-disable-next-line react-hooks/exhaustive-deps
  ), [tempBalance])

  return <header className={b()}>
    <a className={b('item')} href={homeUrl}>
      <SVGInline svg={homeSvg} className={b('icon').toString()} />
    </a>
    <div className={b('user-block')}>
      <div className={b('user-icon')}>
        <SVGInline svg={getIconById(icon_id)} className={b('user-svg').toString()} />
        <div className={b('pencil')} onClick={switchIsOpenEdit}>
          <SVGInline svg={pencil} className={b('pencil-icon').toString()} />
        </div>
        {(nick === '' && icon_id === 0) && 
          <Advice 
            text={locale.editAdvice}
            corner="TopLeft"
            coordinateXL="calc(100% + 5px)" 
            coordinateYT="calc(100% + 5px)" />}
      </div>
      <div className={b('user-info')}>
        <div className={b('user-id')}>{`ID ${userID}`}</div>
        <div className={b('user-nick')}>{nick}</div>
        <div className={b('balance')}>
          {`${(+tempBalance).toFixed(2)} ${currency}`}
          {differentBlock}
        </div>
      </div>
    </div>
    {/* <div className={b('item', {isNewYear: true })}>
      <NewYearButton/>
    </div> */}
    <div className={b('item')}>
      <Settings />
    </div>
    <div className={b('item')}>
      <RulesButton />
    </div>
    <div className={b('burger', { close: isOpenHistory})}>
      <SVGInline
        svg={isOpenHistory ? closeSvg : burgerSvg}
        className={b('burger-icon', { promo: promo.length > 0 }).toString()}
        onClick={switchIsOpenHistory}
      />
      {promo.length > 0 ? <div className={b('promo-count')}>{promo.length}</div> : null}
    </div>
  </header>
}

export default Header;