export const initialState = {
  isAuth: false,
  userID: null,
  nick: 'NONAME',
  icon_id: 0,
  balance: 0,
  currency: '',
  token: null,
  platform: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop',
  isFrame: window !== window.top,
  winAmount: 0,
  homeUrl: '',
  isProcess: false,
  colorTheme: 'orange',
};