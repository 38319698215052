import React, { useCallback } from 'react';
import block from 'bem-cn';

import './PromoListItem.scss';

const b = block('promo-list-item');

const PromoListItem = ({ imgSrc, titleText, description, onClick, id }) => {
    const onClickHandler = useCallback(() => {
        onClick(id);
    }, [onClick, id]);

    return (
        <div className={b()} onClick={onClickHandler}>
            <div className={b('icon-container')}>
                <img className={b('icon')} alt='promoImg' src={imgSrc} />
            </div>
            <div className={b('content')}>
                <div className={b('title')}>
                    {titleText}
                </div>
                <div className={b('descriptions')}>
                    {description}
                </div>
            </div>
        </div>
    );
};

export default PromoListItem;