import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import Coef from './Coef';
import styles from '../../../data';

import './Firework.scss';

const b = block('firework');

const Firework = ({ isPause }) => {
  const periodType = useSelector(state => state.game.periodType);
  const cashedBets = useSelector(state => state.game.cashedBets);
  const styleCode = useSelector(state => state.game.styleCode);
  const userID = useSelector(state => state.auth.userID);
  const locale = useSelector(state => state.locale.locale);
  const roundID = useSelector(state => state.game.periodType);

  const cashedList = useMemo(() => cashedBets.map(t => <div className={b('cashed-item', { isMine: userID === t.user_id, isHorizon: styles[styleCode].isHorizon })} key={`${t.win_amount}${t.user_id}`}>
      {`${t.nick || locale.noName} ${t.win_amount} ${t.currency}`}
    </div>), [cashedBets, locale.noName, styleCode, userID])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const object = useMemo(() => styles[styleCode].isManyObjects ? styles[styleCode].objects?.[Math.trunc(Math.random() * 10)] : styles[styleCode].object, [roundID])

  return <React.Fragment>
    <div className={b('animation-container', {
      type: periodType,
      isAnimation: !isPause,
      isHorizon: styles[styleCode].isHorizon,
      isPauseAnimation: periodType === 2 && !isPause
    })}>
      <div className={b({
        type: periodType,
        isCoefAfterToCenter: styles[styleCode].isCoefAfterToCenter,
        isHorizon: styles[styleCode].isHorizon,
        notNeedAfterAnimation: styles[styleCode].notNeedAfterAnimation,
        isEqualMarginAndHeight: styles[styleCode].isEqualMarginAndHeight,
        notNeedCoefTop: styles[styleCode].notNeedCoefTop,
      })}>
        <Coef />
        <img
          src={periodType === 1 ? object : styles[styleCode].after}
          alt=""
          className={b('rocket', {
            type: periodType,
            notNeedRotate: styles[styleCode].notNeedRotate,
            isSmall: styles[styleCode].isSmall,
            isBig: styles[styleCode].isBig,
            isPauseAnimation: periodType === 2 && !isPause
          })} />
      </div>
      <div className={b('cashed-container')}>
        {cashedList}
      </div>
    </div>
  </React.Fragment>;
}

export default Firework;