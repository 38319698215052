import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { actions } from 'features/Game';
import { roundingFunc } from 'services/helpers/roundingFunc';

import Button from 'components/Button';
import SwitchBox from 'components/SwitchBox';
import Input from 'components/Input';
import Keyboard from './Keyboard';
import minusSvg from './img/minus.svg';
import plusSvg from './img/plus.svg';
import './Basket.scss';
import { useMemo } from 'react';

const Basket = ({ callback, isAlone, index }) => {
  const dispatch = useDispatch();
  const limits = useSelector(state => state.game.limits);
  const [isPlaced, setPlaced] = useState(false);
  const [isOpenKeyboard, changeIsInputKeyboard] = useState(false);
  const [isOpenKeyboardCoef, changeIsInputKeyboardCoef] = useState(false);
  const [isAutoBet, setAutoBet] = useState(false);
  const [isAutoCashout, setAutoCashout] = useState(false);
  const [amount, setAmount] = useState(0);
  const [isBlock, setBlock] = useState(false);
  const [coef, setCoef] = useState(5);
  const currency = useSelector(state => state.auth.currency);
  const tempCoef = useSelector(state => state.game.coef);
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang);
  const platform = useSelector(state => state.auth.platform);
  const balance = useSelector(state => state.auth.balance);
  const periodType = useSelector(state => state.game.periodType);
  const isProcess = useSelector(state => state.game.isProcess);
  const tempBet = useSelector(state => state.game.cashedBets)?.find(t => t.bet_id === isPlaced);
  const b = block(platform === 'mobile' ? 'basket' : 'basket-desktop');

  // Reset bet
  useEffect(() => {
    if (tempBet && isPlaced) {
      setPlaced(false)
    }
  }, [isPlaced, tempBet])

  // Set block
  useEffect(() => {
    if (isPlaced || isProcess) {
      setBlock(true)
    } else {
      setBlock(false)
    }
  }, [isPlaced, isProcess])

  // Set limits
  useEffect(() => setAmount(limits.min), [limits.min])

  // Place bet function
  const placeBet = useCallback(() => dispatch(actions.placeBet(+amount, +coef, isAutoCashout, setPlaced, isAutoBet, index)),
  [amount, coef, dispatch, isAutoBet, isAutoCashout, index]);

  // Cashout money function
  const cashoutBet = useCallback(() => {
    dispatch(actions.cashoutBet(isPlaced, setPlaced))
  },
  [dispatch, isPlaced]);

  const cancelBet = useCallback(() => {
    dispatch(actions.cancelBet(isPlaced, setPlaced))
  }, [dispatch, isPlaced])

  // Auto bet
  useEffect(() => {
    if (periodType === 2) setPlaced(false);
    if (periodType === 2 && isAutoBet) {
      placeBet();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [periodType])

  // For validate amount
  const validateChangeAmount = useCallback(value => {
    if (!isBlock) {
      if (value > limits.max) setAmount(+limits.max.toFixed(1))
      else if (value < limits.min) setAmount(+limits.min.toFixed(1))
      else if (coef * value > limits.win) setAmount(+(limits.win / coef).toFixed(1))
      else if (+(+value).toFixed(1) > balance) {
        if (balance < limits.min) {
          setAmount(+limits.min.toFixed(1))
        } else {
          setAmount(Math.floor(balance * 10, 1) / 10)
        }
      }
      else setAmount(+(+value).toFixed(1));
    }
  }, [balance, coef, isBlock, limits.max, limits.min, limits.win])

  // For validate input Amount
  const validateTempAmount = useCallback(value => {
    if (!isBlock) {
      if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
      value.toString().split('').filter(t => t === '.').length < 2) {
        if (value.toString().split('')[0] === '.') {
          setAmount(value.substring(1, value.length))
        } else setAmount(value)
      }
    }
  }, [isBlock])

  // For minus amount
  const minusAmount = useCallback(value => {
    if (!isBlock) {
      if (value >= limits.min) setAmount(+(value.toFixed(1)))
      else setAmount(+(limits.min.toFixed(1)));
    }
  }, [isBlock, limits.min])

  // For validate input coef
  const validateChangeCoef = value => {
    if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
    value.toString().split('').filter(t => t === '.').length < 2 && isAutoCashout) {
      if (value < 1.01 && value !== '' && +value !== 1) setCoef(1.01)
      else if (amount * value > limits.win) setCoef(limits.win / amount)
      else setCoef(value);
    }
  }

  // For validate after blur coef
  const validateBlurCoef = value => {
    if (isAutoCashout) {
      if (+value > 1.1) {
        setCoef(+(+value).toFixed(2))
      } else {
        setCoef(1.01)
      }
    }
  }

  // For keyboard validate coef
  const keyboardCoefValidate = value => {
    if ((!isNaN(+value) || value.toString().indexOf('.') === value.toString().length - 1) &&
    value.toString().split('').filter(t => t === '.').length < 2) {
      if (value < 1.01) validateBlurCoef(1.01)
      else if (amount * value > limits.win) validateBlurCoef(limits.win / amount)
      else validateBlurCoef(value);
    }
  }

  // Set button click func
  const tempFunc = useMemo(() => {
    if (isPlaced) {
      if (periodType === 1) {
        return cashoutBet
      } else {
        // return cancelBet
      }
    } 
    if (!isPlaced) {
      return placeBet
    }
  },
    [isPlaced, periodType, cashoutBet, placeBet]
  )

  // Set button text
  const tempButtonText = useMemo(() => {
    if (isPlaced) {
      if (periodType === 1) {
        return `${locale.cashout} ${periodType === 1 ? `${roundingFunc(tempCoef * amount)} ${currency}` : ''}`
      } else {
        return `${locale.cashout} ${periodType === 1 ? `${roundingFunc(tempCoef * amount)} ${currency}` : ''}`
      }
    }
    if (!isPlaced) {
      return locale.placeBet
    }
  },
    [isPlaced, periodType, locale.cashout, locale.placeBet, tempCoef, amount, currency]
  )

  // Not one basket
  const isDouble = useMemo(() => !isAlone, [isAlone])

  // Is minimal amount
  const isMinimalAmount = useMemo(() => limits.min === amount, [amount, limits.min])

  return platform === 'mobile' ?
  // MOBILE
  <article className={b({ isBlock })}>
    <div className={b('bets-container')}>
      <div className={b('left')}>
        <div className={b('row', { alwaysActive: true })}>
          <span className={b('text')}>{locale.autoBet}</span>
          <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
        </div>
        <div className={b('row')}>
          <div className={b('change-container')}>
            <div className={b('change-block', { isMinimalAmount })} onClick={() => minusAmount(amount - limits.min)}>
              <SVGInline svg={minusSvg} className={b('change-icon').toString()} />
            </div>
            <div className={b('change-block')} onClick={f => platform === 'mobile' ? changeIsInputKeyboard(true) : f}>
              {`${amount} ${currency}`}
            </div>
            <div className={b('change-block')} onClick={() => validateChangeAmount(amount + limits.min)}>
              <SVGInline svg={plusSvg} className={b('change-icon').toString()} />
            </div>
          </div>
        </div>
        <div className={b('row')}>
          <div className={b('bet-block')} onClick={() => validateChangeAmount(limits.min)}>
            {locale.min}
          </div>
          <div className={b('bet-block')} onClick={() => validateChangeAmount(amount * 2)}>
            x2
          </div>
          <div className={b('bet-block')} onClick={() => validateChangeAmount(balance)}>
            {locale.max}
          </div>
        </div>
      </div>
      <div className={b('right')}>
        <div className={b('row')}>
          <span className={b('text')}>{locale.autoCashout}</span>
          <SwitchBox value={isAutoCashout} changeValue={value => isBlock ? isBlock : setAutoCashout(value)} />
        </div>
        <div className={b('row')}>
          <div className={b('change-container', { isBlock: !isAutoCashout })}>
            <div className={b('change-block')} onClick={() => keyboardCoefValidate(+coef - 0.25)}>
              <SVGInline svg={minusSvg} className={b('change-icon').toString()} />
            </div>
            <div className={b('change-block')} onClick={f => platform === 'mobile' ? isAutoCashout ? changeIsInputKeyboardCoef(true) : f : f}>
              {`${coef} X`}
            </div>
            <div className={b('change-block')} onClick={() => keyboardCoefValidate(+coef + 0.25)}>
              <SVGInline svg={plusSvg} className={b('change-icon').toString()} />
            </div>
          </div>
        </div>
        <div className={b('row')}>
          <Button
            text={tempButtonText}
            size='container'
            isUppercase
            isBlock={isBlock}
            fontSize="small"
            isDisabled={isProcess || (periodType === 1 && !isPlaced)}
            color={isPlaced && periodType === 2 ? 'red' : 'orange'}
            callBack={tempFunc} />
        </div>
      </div>
    </div>
    <div className={b('more-basket')} onClick={f => (!isPlaced || isAlone) ? callback() : f}>
      <SVGInline svg={isAlone ? plusSvg : minusSvg} className={b('more-plus').toString()} />
    </div>
    {isOpenKeyboard && <Keyboard initialAmount={amount} acceptAmount={validateChangeAmount} closeKeyboard={() => changeIsInputKeyboard(false)} />}
    {isOpenKeyboardCoef && <Keyboard initialAmount={coef} acceptAmount={keyboardCoefValidate} closeKeyboard={() => changeIsInputKeyboardCoef(false)} />}
    </article> :
    // DESKTOP
    <article className={b({ isBlock, isDouble, lang })}>
      {isAlone ? <React.Fragment>
        <div className={b('bets-container')}>
        <div className={b('left')}>
          <div className={b('row')}>
            <div className={b('change-container')}>
              <div className={b('change-block', { isMinimalAmount })} onClick={() => validateChangeAmount(amount - limits.min)}>
                <SVGInline svg={minusSvg} className={b('change-icon').toString()} />
              </div>
              <div className={b('change-block')}>
                <Input
                  value={amount}
                  onChange={e => validateTempAmount(e.currentTarget.value)}
                  onBlur={() => validateChangeAmount(amount)}
                  unBorder
                  centerText />
              </div>
              <div className={b('change-block')} onClick={() => validateChangeAmount(amount + limits.min)}>
                <SVGInline svg={plusSvg} className={b('change-icon').toString()} />
              </div>
            </div>
          </div>
          <div className={b('row')}>
            <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(limits.min)}>
              {locale.min}
            </div>
            <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(amount * 2)}>
              x2
            </div>
          </div>
          <div className={b('row')}>
            <div className={b('bet-block',  { size: 'big' })} onClick={() => validateChangeAmount(amount * 3)}>
              x3
            </div>
            <div className={b('bet-block', { size: 'big' })} onClick={() => validateChangeAmount(balance)}>
              {locale.allIn}
            </div>
          </div>
          <div className={b('row', { alwaysActive: true })}>
            <span className={b('text')}>{locale.autoBet}</span>
            <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
          </div>
        </div>
        <div className={b('right')}>
          <div className={b('row')}>
            <div className={b('change-container', { isBlock: !isAutoCashout })}>
              <div className={b('change-block')} onClick={() => keyboardCoefValidate(+coef - 0.25)}>
                <SVGInline svg={minusSvg} className={b('change-icon').toString()} />
              </div>
              <div className={b('change-block')}>
                <Input
                  value={coef}
                  onChange={e => validateChangeCoef(e.currentTarget.value)}
                  onBlur={() => validateBlurCoef(coef)}
                  label={'X'}
                  unBorder
                  centerText />
              </div>
              <div className={b('change-block')} onClick={() => keyboardCoefValidate(+coef + 0.25)}>
                <SVGInline svg={plusSvg} className={b('change-icon').toString()} />
              </div>
            </div>
          </div>
          <div className={b('row')}>
            <span className={b('text')}>{locale.autoCashout}</span>
            <SwitchBox value={isAutoCashout} changeValue={value => isBlock ? isBlock : setAutoCashout(value)} />
          </div>
          <div className={b('row', { size: 'big' })}>
            <Button
              text={tempButtonText}
              size='container'
              isUppercase
              isDisabled={isProcess || (periodType === 1 && !isPlaced)}
              color={isPlaced && periodType === 2 ? 'red' : 'orange'}
              isBlock={isBlock}
              callBack={tempFunc} />
          </div>
        </div>
      </div>
      <div className={b('more-basket')} onClick={f => (!isPlaced || isAlone) ? callback() : f}>
        <SVGInline svg={isAlone ? plusSvg : minusSvg} className={b('more-plus').toString()} />
      </div>
      </React.Fragment> :
      <React.Fragment>
        <div className={b('row')}>
          <div className={b('change-container')}>
            <div className={b('change-block', { isMinimalAmount })} onClick={() => validateChangeAmount(amount - limits.min)}>
              <SVGInline svg={minusSvg} className={b('change-icon').toString()} />
            </div>
            <div className={b('change-block')}>
              <Input
                value={amount}
                onChange={e => validateTempAmount(e.currentTarget.value)}
                onBlur={() => validateChangeAmount(amount)}
                unBorder
                centerText />
            </div>
            <div className={b('change-block')} onClick={() => validateChangeAmount(amount + limits.min)}>
              <SVGInline svg={plusSvg} className={b('change-icon').toString()} />
            </div>
          </div>
          <div className={b('switch-container')}>
            <span className={b('text')}>{locale.autoBet}</span>
            <SwitchBox value={isAutoBet} changeValue={value => setAutoBet(value)} />
          </div>
          <div className={b('more-basket')} onClick={f => (!isPlaced || isAlone) ? callback() : f}>
            <SVGInline svg={isAlone ? plusSvg : minusSvg} className={b('more-plus').toString()} />
          </div>
        </div>
        <div className={b('row')}>
          <div className={b('bet-block')} onClick={() => validateChangeAmount(limits.min)}>
            {locale.min}
          </div>
          <div className={b('bet-block')} onClick={() => validateChangeAmount(amount * 2)}>
            x2
          </div>
          <div className={b('bet-block')} onClick={() => validateChangeAmount(amount * 3)}>
            x3
          </div>
          <div className={b('bet-block')} onClick={() => validateChangeAmount(balance)}>
            {locale.allIn}
          </div>
        </div>
        <div className={b('row')}>
          <div className={b('change-container', { isBlock: !isAutoCashout })}>
            <div className={b('change-block')} onClick={() => keyboardCoefValidate(+coef - 0.25)}>
              <SVGInline svg={minusSvg} className={b('change-icon').toString()} />
            </div>
            <div className={b('change-block')}>
              <Input
                value={coef}
                onChange={e => validateChangeCoef(e.currentTarget.value)}
                onBlur={() => validateBlurCoef(coef)}
                label={'X'}
                unBorder
                centerText />
            </div>
            <div className={b('change-block')} onClick={() => keyboardCoefValidate(+coef + 0.25)}>
              <SVGInline svg={plusSvg} className={b('change-icon').toString()} />
            </div>
          </div>
          <div className={b('switch-container')}>
            <span className={b('text')}>{locale.autoCashout}</span>
            <SwitchBox value={isAutoCashout} changeValue={value => isBlock ? isBlock : setAutoCashout(value)} />
          </div>
        </div>
        <div className={b('row')}>
          <Button
            text={tempButtonText}
            size='container'
            isUppercase
            isDisabled={isProcess || (periodType === 1 && !isPlaced)}
            color={isPlaced && periodType === 2 ? 'red' : 'orange'}
            isBlock={isBlock}
            callBack={tempFunc} />
        </div>
      </React.Fragment>}
    </article>
}

export default Basket;