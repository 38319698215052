export const roundingFunc = data => {
    if (data < 1000) {
        return data.toFixed(2)
    } else if (data < 1000000) {
        return `${(data / 1000)}K`
    } else if (data < 1000000000) {
        return `${(data / 1000000)}M`
    } else {
        return `${(data / 1000000000)}B`
    }
};