import bgMusic from 'services/sounds/backgrounds';
import afterMusic from 'services/sounds/afters';

import * as defaultStyles from './default';
import * as footballStyles from './football';
import * as desertStyles from './desert';
import * as marioStyles from './mario';
import * as dinoStyles from './dino';
import * as ragbyStyles from './ragby';
import * as knightStyles from './knight';
import * as smurfStyles from './smurf';
import * as squidStyles from './squid';

const styles = {
  default: {
    object: defaultStyles.object,
    background: defaultStyles.bg,
    after: defaultStyles.after,
    isCoefAfterToCenter: true,
  },
  desert: {
    object: desertStyles.object,
    background: desertStyles.bg,
    after: desertStyles.after,
    loading: desertStyles.loading,
    isCoefAfterToCenter: true,
    notNeedSparks: true,
    notNeedRotate: true,
    isSmall: true,
    isHorizon: true, 
    notNeedCoefTop: true,
    backgroundMusic: bgMusic.desert,
    afterMusic: afterMusic.desert,
    loadingHeight: '6.3rem',
    title: 'ALADINCRASH',
  },
  football: {
    object: footballStyles.object,
    background: footballStyles.bg,
    after: footballStyles.after,
    isCoefAfterToCenter: true,
  },
  mario: {
    object: marioStyles.object,
    background: marioStyles.bg,
    after: marioStyles.after,
    loading: marioStyles.loading,
    loadingHeight: '5rem',
    notNeedSparks: true,
    notNeedRotate: true,
    isSmall: true,
    isHorizon: true, 
    isCoefAfterToCenter: false,
    backgroundMusic: bgMusic.mario,
    afterMusic: afterMusic.mario,
    title: 'MARIOCRASH',
  },
  dino: {
    object: dinoStyles.object,
    background: dinoStyles.bg,
    after: dinoStyles.after,
    loading: dinoStyles.loading,
    loadingObject: dinoStyles.loadingObject,
    loadingHeight: '3.5rem',
    loadingObjectHeight: '6rem',
    loadingObjectLeft: '-3rem',
    notNeedRotate: true,
    isBig: true,
    isHorizon: true, 
    isCoefAfterToCenter: true,
    backgroundMusic: bgMusic.dino,
    afterMusic: afterMusic.dino,
    notNeedAfterAnimation: true,
    title: 'DINOCRASH',
  },
  ragby: {
    object: ragbyStyles.object,
    objects: ragbyStyles.objects,
    isManyObjects: true,
    background: ragbyStyles.bg,
    after: ragbyStyles.after,
    loading: ragbyStyles.loading,
    loadingHeight: '20rem',
    loadingObjectHeight: '20rem',
    notNeedRotate: true,
    notNeedSparks: true,
    isHorizon: true, 
    isEqualMarginAndHeight: true,
    isCoefAfterToCenter: true,
    backgroundMusic: bgMusic.ragby,
    afterMusic: afterMusic.ragby,
    notNeedAfterAnimation: true,
    pauseBackgroundSize: 'auto',
  },
  knight: {
    object: knightStyles.object,
    background: knightStyles.bg,
    after: knightStyles.after,
    loading: dinoStyles.loading,
    loadingObject: dinoStyles.loadingObject,
    loadingHeight: '3.5rem',
    loadingObjectHeight: '6rem',
    loadingObjectLeft: '-3rem',
    notNeedRotate: true,
    isHorizon: true, 
    isEqualMarginAndHeight: true,
    isCoefAfterToCenter: true,
    backgroundMusic: bgMusic.dino,
    afterMusic: afterMusic.dino,
    notNeedAfterAnimation: true,
  },
  smurf: {
    object: smurfStyles.object,
    background: smurfStyles.bg,
    after: smurfStyles.after,
    loading: smurfStyles.loading,
    loadingObject: smurfStyles.loadingObject,
    backgroundMusic: bgMusic.smurfs,
    afterMusic: afterMusic.smurfs,
    loadingHeight: '8rem',
    loadingObjectHeight: '10rem',
    loadingObjectLeft: '-3rem',
    notNeedRotate: true,
    isHorizon: true, 
    isEqualMarginAndHeight: true,
    isCoefAfterToCenter: false,
    notNeedAfterAnimation: false,
    title: 'SMURFSCRASH',
  },
  squid: {
    object: squidStyles.object,
    background: squidStyles.bg,
    after: squidStyles.after,
    loading: squidStyles.loading,
    loadingHeight: '7rem',
    notNeedRotate: true,
    notNeedSparks: true,
    isBig: false,
    isHorizon: true, 
    isCoefAfterToCenter: true,
    backgroundMusic: bgMusic.squid,
    afterMusic: afterMusic.squid,
    gameMusic: bgMusic.squidGame,
    notNeedAfterAnimation: true,
    isEqualMarginAndHeight: true,
    endObject: squidStyles.endObject,
    endObjectAfter: squidStyles.endObjectAfter,
    title: 'SQUIDCRASH',
  },
}

export default styles;