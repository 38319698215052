import React from 'react';
import { useSelector } from 'react-redux';

import megaWinMp3 from './sound/megaWin.mp3';

const MegaWinMusic = () => {
  const { isSound } = useSelector(state => state.settings);
  
  return <React.Fragment>
    {isSound && <audio src={megaWinMp3} autoPlay />}
  </React.Fragment>
}

export default MegaWinMusic;