import React, { useCallback} from 'react';
import SVGInline from 'react-svg-inline';
import block from 'bem-cn';

import closeSvg from './img/close.svg';

import './Modal.scss';

const b = block('modal');

const Modal = ({ close = f => f, children = null, isCross = false }) => {
  const stop = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, [])

  return <div className={b('wrapper')} onClick={() => close()}>
    <article className={b()} onClick={stop}>
      {children}
      {isCross && <div className={b('cross')} onClick={close}>
        <SVGInline className={b('close').toString()} svg={closeSvg} />
      </div>}
    </article>
  </div>;
}

export default Modal;