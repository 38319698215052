import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import { GridLoader } from 'react-spinners';

import { actions } from '../';
import { roundingFunc } from 'services/helpers/roundingFunc';

import './MyBets.scss';

const LOADER_STYLE = { position: 'fixed', top: '50%', transform: 'translateY(-50%)', zIndex: 100 };

const MyBets = () => {
  const b = block('my-bets');
  const locale = useSelector(state => state.locale.locale);
  const lang = useSelector(state => state.locale.lang)
  const { myBets, isProcess } = useSelector(state => state.history);
  const { roundID } = useSelector(state => state.game);
  const currency = useSelector(state => state.auth.currency);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getMyBets());
  }, [dispatch, roundID])

  const resultList = useMemo(() => (
    myBets.map((t, i) => (
      <div key={`${t.value}${t.round_id}${i}`} className={b('item')}>
        <div className={b('column')}>
          {t.game_id}
        </div>
        <div className={b('column')}>
          {`${roundingFunc(t.bet_amount)} ${currency}`}
        </div>
        <div className={b('column')}>
          {t.coefficient}
        </div>
        <div className={b('column')}>
          {`${roundingFunc(t.win_amount)} ${currency}`}
        </div>
      </div>
    ))
  ), [b, currency, myBets])

  return <section className={b()}>
    <GridLoader loading={isProcess} color="var(--accent-color)" size={50} css={LOADER_STYLE}/>
    <div className={b('header', { lang })}>
      <div className={b('column')}>
        {locale.historyRoundID}
      </div>
      <div className={b('column')}>
        {locale.historyBetAmount}
      </div>
      <div className={b('column')}>
        {locale.historyOdd}
      </div>
      <div className={b('column')}>
        {locale.historyWinAmount}
      </div>
    </div>
    <div className={b('items')}>
      {resultList}
    </div>
  </section>
}

export default MyBets;