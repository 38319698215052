const actionTypes = {
  SHOW_SOCKET_GAME_ERROR: 'socketError/SHOW_SOCKET_GAME_ERROR',
  SHOW_SOCKET_CHAT_ERROR: 'socketError/SHOW_SOCKET_CHAT_ERROR',
}

const showGameSessionError = () => async (dispatch, getState, extra) => {
    const { locale } = getState().locale;
    extra.socket.handleSessionClose();
    dispatch({ type: actionTypes.SHOW_SOCKET_GAME_ERROR, payload: locale.errorMessages.sessionError });
}

const showGameTokenError = () => async (dispatch, getState, extra) => {
    const { locale } = getState().locale;
    extra.socket.handleSessionClose();
    dispatch({ type: actionTypes.SHOW_SOCKET_GAME_ERROR, payload: locale.errorMessages.tokenError });
}

const showChatSessionError = () => async (dispatch, getState, extra) => {
  const { locale } = getState().locale;
  extra.chatSocket.handleSessionClose();
  dispatch({ type: actionTypes.SHOW_SOCKET_CHAT_ERROR, payload: locale.errorMessages.sessionError });
}

const showChatTokenError = () => async (dispatch, getState, extra) => {
  const { locale } = getState().locale;
  extra.chatSocket.handleSessionClose();
  dispatch({ type: actionTypes.SHOW_SOCKET_CHAT_ERROR, payload: locale.errorMessages.tokenError });
}

export {
  actionTypes,
  showGameSessionError,
  showGameTokenError,
  showChatSessionError,
  showChatTokenError,
}
