import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './Coef.scss';

const b = block('coef');

const Coef = ({ isOnly }) => {
  const coef = useSelector(state => state.game.coef);
  const periodType = useSelector(state => state.game.periodType);

  return <div className={b({ isOnly, isStop: isOnly && periodType === 2 })}>
    {`${coef.toFixed(2)} x`}
  </div>;
}

export default Coef;