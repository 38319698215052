import React from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import img from './img/img.svg';

import './SocketErrorBoundary.scss';

const b = block('socket-error');

const SocketErrorBoundary = ({ error }) => {
    return (
        <div className={b()}>
            <SVGInline  svg={img} className={b('img').toString()} alt='img' />
            {error}
        </div>
    );
};

export default SocketErrorBoundary;