import { initialState } from './initial';
import { actionTypes } from './actions';

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SHOW_SOCKET_GAME_ERROR:
      return {
        ...state,
        gameSocketError: payload,
        isGameSocketError: true,
      }

    case actionTypes.SHOW_SOCKET_CHAT_ERROR:
      return {
        ...state,
        chatSocketError: payload,
        isChatSocketError: true
      }

    default: return state;
  }
}