import dayjs from 'dayjs';
import { languages } from 'services/locale';
import { errors } from 'projects-translates-errors';

import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/ru';
import 'dayjs/locale/es';
import 'dayjs/locale/fa';
import 'dayjs/locale/pt';
import 'dayjs/locale/it';
import 'dayjs/locale/ar';
import 'dayjs/locale/zh';
import 'dayjs/locale/sq';

const actionTypes = {
  CHANGE_LANG: 'locale/CHANGE_LANG',
};

function changeLang(lang = 'fr') {
  return async dispatch => {
    const { locale } = await import(`services/locale/${lang}/index`);
    document.querySelector('html').lang = languages[lang];
    dayjs.locale(languages[lang]);
    dispatch({ type: actionTypes.CHANGE_LANG, payload: { locale: { ...locale, errorMessages: errors[lang] || errors.en }, lang } });
  };
}

export {
  actionTypes,
  changeLang,
};