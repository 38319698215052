import { roundingFunc } from 'services/helpers/roundingFunc';

const actionTypes = {
    SHOW_MEGA_WINS: 'megaWins/SHOW_MEGA_WINS',
    CLOSE_MEGA_WINS: 'megaWins/CLOSE_MEGA_WINS',
};

let lastId = 0;

const getNextId = () => lastId++;

const showMegaWins = winAmount => async (dispatch) => {
    dispatch({ type: actionTypes.SHOW_MEGA_WINS, payload: { winId: getNextId(), winAmount: roundingFunc(Number(winAmount)) } });
};

const closeMegaWins = id => async dispatch => {
    dispatch({ type: actionTypes.CLOSE_MEGA_WINS, payload: id });
};

export {
    actionTypes,
    showMegaWins,
    closeMegaWins,
};