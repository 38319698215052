import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions as historyActions } from 'features/History';

import Firework from './Firework';
import Pause from './Pause';
import Coef from './Firework/Coef';

import styles from '../../data';

import './GameWindow.scss';

const b = block('game-window');

const GameWindow = () => {
  const dispatch = useDispatch();
  const [isPause, setPause] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const periodType = useSelector(state => state.game.periodType);
  const styleCode = useSelector(state => state.game.styleCode);
  const isAnimations = useSelector(state => state.settings.isAnimations);

  useEffect(() => {
    if (periodType === 2) {
      setPause(false)
      dispatch(historyActions.clearBets());
      if (!timeoutId) {
        setTimeoutId(setTimeout(() => setPause(true), 2000));
      }
    }
    if (periodType === 1) {
      setPause(false)
      if (timeoutId) {
        clearTimeout(timeoutId);
        setTimeoutId(null)
      }
    }
  }, [periodType, dispatch, timeoutId])

  const endObject = useMemo(() => styles[styleCode].endObjectAfter ? <img
  className={b('end-image')}
  src={periodType === 2 && !isPause ? styles[styleCode].endObjectAfter : null}
  alt="" /> : null, [isPause, periodType, styleCode])

  return <article className={b({
      isAnimation: !isPause && isAnimations,
      isPauseAnimation: periodType === 2 && !isPause,
      styleCode: styleCode || 'default'
    })}
      style={{ background: `#da6ba8 url(${styles[styleCode].background})`, backgroundSize: '200% 400%', backgroundPosition: '0 100%' }}>
    {(isPause && periodType === 2) ? <Pause /> : isAnimations ? <Firework isPause={isPause} /> : <Coef isOnly/>}
    {endObject}
  </article>;
}

export default GameWindow;