const categories = {
  bets: {
    id: 0,
    textIdent: 'bets',
  },
  myBets: {
    id: 1,
    textIdent: 'myBets',
  },
  results: {
    id: 2,
    textIdent: 'topWins',
  },
  promoList: {
    id: 6,
    textIdent: 'promoList',
  },
}

export { categories };