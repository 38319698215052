import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import Button from 'components/Button';
import { numbers } from './data';
import backspaceSvg from './img/backspace.svg';
import closeSvg from './img/close.svg';

import './Keyboard.scss';

const Keyboard = ({ initialAmount, acceptAmount, closeKeyboard }) => {
  const b = block('keyboard');
  const [amount, changeAmount] = useState(initialAmount);
  const [isClosing, changeIsClosing] = useState(false);

  const locale = useSelector(state => state.locale.locale);

  const buttons = numbers.map(tempButton => (
    <div key={tempButton} className={b('button')} onClick={() => changeAmount(amount.toString() + tempButton.toString())}>
      {tempButton}
    </div>
  ))

  const onSubmit = useCallback((isSave = true) => {
    if (isSave) {
      acceptAmount(+amount);
    }
    changeIsClosing(true);
    setTimeout(() => closeKeyboard(), 500);
  }, [acceptAmount, amount, closeKeyboard])

  return <div className={b({ isClosing })}>
    <div className={b('amount')}>
      {amount}
      <div className={b('close')} onClick={() => onSubmit(false)}>
        <SVGInline svg={closeSvg} className={b('close-svg').toString()} />
      </div>
    </div>
    <div className={b('buttons')}>
      {buttons}
    </div>
    <div className={b('bottom-buttons')}>
      <div className={b('bottom-button')} onClick={f => amount.toString().indexOf('.') === -1 ? changeAmount(amount.toString() + '.') : f}>
        .
      </div>
      <div className={b('bottom-button')} onClick={() => changeAmount(amount.toString().substring(0, amount.toString().length - 1))}>
        <SVGInline svg={backspaceSvg} className={b('backspace').toString()} />
      </div>
      <div className={b('submit-container')}>
        <Button
          text={locale.ok}
          size='container'
          isUppercase
          color='dark'
          callBack={onSubmit}
          fontSize='large'/>
      </div>
    </div>
  </div>
}

export default Keyboard;