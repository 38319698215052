import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';

import { actions } from '../';

import Input from 'components/Input';
import Messages from './Messages';
import { SocketErrorBoundary } from 'features/SocketErrorBoundary';

import ellipseSvg from './img/orange-ellipse.svg';
import arrowSvg from './img/chat-arrow.svg';
import sendSvg from './img/send.svg';

import './Chat.scss';

const b = block('chat');

const Chat = ({ scrollRef }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(true);
  const [text, setText] = useState('');
  const online = useSelector(state => state.chat.online);
  const locale = useSelector(state => state.locale.locale);
  const { isChatSocketError, chatSocketError } = useSelector(state => state.socketError);

  const textValidator = useCallback(value => setText(value.substring(0, 250)), []);

  const sendMessage = useCallback(() => {
    if (text.length > 0) dispatch(actions.sendMessage(text, () => setText('')))
  }, [dispatch, text])

  const setOpenWithScroll = useCallback(() => {
    setOpen(!isOpen);
    if (!isOpen && scrollRef) setTimeout(() => {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }, 1100)
  }, [isOpen, scrollRef])
  
  return isChatSocketError ? <SocketErrorBoundary error={chatSocketError} /> : <article className={b()}>
    <div className={b('top-block')}>
      <SVGInline svg={ellipseSvg} className={b('ellipse').toString()} />
      {`${locale.online} (${(online || 0) + 4})`}
      <div className={b('switcher')} onClick={setOpenWithScroll}>
        {isOpen ? locale.closeChat : locale.openChat}
        <SVGInline svg={arrowSvg} className={b('arrow', { isOpen }).toString()} />
      </div>
    </div>
    <div className={b('messages', { isOpen })}>
      <Messages />
    </div>
    <div className={b('input-container')}>
      <Input
        type="textarea"
        value={text}
        placeholder={`${locale.enterMessage}...`}
        callBack={textValidator}
      />
    </div>
    <div className={b('bottom-block')}>
      {250 - text.length}
      <SVGInline
        svg={sendSvg}
        onClick={sendMessage}
        className={b('send', { unactive: text.length === 0 }).toString()}
      />
    </div>
  </article>
}

export default Chat;