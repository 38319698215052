import React, { useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';

import { actions } from '../';
import Button from 'components/Button';
import closeImg from 'features/Auth/view/img/close.svg';

import './Promo.scss';

const b = block('promo');

const Promo = () => {
    const dispatch = useDispatch();
    const openPromo = useSelector(state => state.promo.promo?.find(item => item.id === state.promo.openPromoId));
    const { locale } = useSelector(state => state.locale);

    const onCloseButtonClick = useCallback(() => {
        dispatch(actions.closePromo());
    },[dispatch])

    return !openPromo ? null : (
        <div className={b()}>
            <div className={b('content')}>
                <div className={b('title')}>
                    {openPromo?.name}
                </div>
                <div className={b('text')}>
                    {openPromo?.text || ''}
                </div>
                <div className={b('img-container')}>
                    <img className={b('img')} alt='backgroundImg' src={openPromo?.image || ''} />
                </div>
                <div className={b('close-button-container')} onClick={onCloseButtonClick} >
                    <SVGInline svg={closeImg} className={b('close-button').toString()} alt='closeButton' />
                </div>
                <div className={b('button')}>
                    <Button
                        text={locale.closePromo}
                        color='orange'
                        isUppercase
                        fontSize={'extra'}
                        size={'container'}
                        callBack={onCloseButtonClick}
                    />
                </div>
            </div>
        </div>
    )
}

export default Promo;